import {FrontendConfiguration} from "@common/configuration";
import moment from "moment-timezone";
import isNil from "lodash/isNil";

export const getCheckinDateFormatted = (checkingDate: string, event: any): string => {
    let dateDescription = "";
    if (!isNil(checkingDate) && checkingDate === moment.tz(event.eligibility.end, FrontendConfiguration.getTimeZone()).format("YYYY-MM-DD")) {
        dateDescription = `Checking-in ${moment.tz(event.eligibility.end, FrontendConfiguration.getTimeZone()).format("YYYY-MM-DD")}`;
    } else if (event.active) {
        dateDescription = moment.tz(event.eligibility.end, FrontendConfiguration.getTimeZone()).format("[Ends] h:mma z [on] YYYY-MM-DD");
    } else {
        dateDescription = `From ${
            !isNil(event.eligibility.start) ? moment.tz(event.eligibility.start, FrontendConfiguration.getTimeZone()).format("YYYY-MM-DD") : null
        }\xa0\xa0to\xa0\xa0${
            !isNil(event.eligibility.end) ? moment.tz(event.eligibility.end, FrontendConfiguration.getTimeZone()).format("YYYY-MM-DD") : null
        }`;
    }
    return dateDescription;
};

export const calculateDaysUntilCheckin = (checkinDate: string): number => {
    let daysUntilCheckin: number = null;
    if (!isNil(checkinDate)) {
        daysUntilCheckin = moment(checkinDate).diff(moment().tz(FrontendConfiguration.getTimeZone()).format("YYYY-MM-DD"), "days");
        if (daysUntilCheckin < 0) {
            daysUntilCheckin = 0;
        }
    }

    return daysUntilCheckin;
};
