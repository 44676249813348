import React, {useEffect, useState} from "react";
import {ResManService} from "@common/services";
import isNil from "lodash/isNil";
import jwtDecode from "jwt-decode";
import {FrontendConfiguration} from "@common/configuration";
import {calculateDaysUntilCheckin, getTripProtectionName, parseAdjustmentsToEvents} from "../utils";
import {
  AddonAttributes,
  AdjustmentsData,
  CancellationSchedule,
  CancellationScheduleAttributes,
  CancellationScheduleEventsParsed,
} from "../typing/cancel-schedule";
import {DataType} from "@common/typing";
import {TRAVEL_INSURANCES, TripProtectionProviders} from "../constants/constants";

type Props = {
  reservationUuid?: string;
  children?: React.ReactNode;
};

export const CancellationScheduleContext = React.createContext<any>({});

const CancellationScheduleProvider = ({reservationUuid, children}: Props) => {
  const resManService = ResManService.getInstance();
  const resInfo = jwtDecode(window.localStorage.getItem(FrontendConfiguration.tokenStorageName)) as any;
  const [adjustments, setAdjustments] = useState<AdjustmentsData>(null);
  const [adjustmentsEvents, setAdjustmentsEvents] = useState<CancellationScheduleEventsParsed[]>(null);
  const [daysUntilCheckin, setDaysUntilCheckin] = useState<number>(null);
  const [requestFlag, setRequestFlag] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        if (isNil(adjustments)) {
          let adjustmentsData: AdjustmentsData = null;
          const reservation = await resManService.getReservationById(resInfo?.reservation_uuid, ["cancellation_schedule", "addons"]);

          if (reservation) {
            const cancelSchedule: CancellationSchedule = reservation?.included?.filter(
              (includedItem: DataType<CancellationScheduleAttributes>) => includedItem.type === "cancellation_schedule"
            )[0];
            const tripProtectionAddon = reservation?.included?.filter(
              (includedItem: DataType<AddonAttributes<any>>) => includedItem.type === "addon" && includedItem.attributes.name === "trip_protection"
            )[0];

            // Reservation Service is not returning the trip_protection_fee_id for Red Sky, so we are using the default one
            const tripProtectionFeeId =
              tripProtectionAddon?.attributes?.meta?.trip_protection_fee_id ?? TRAVEL_INSURANCES[TripProtectionProviders.RED_SKY].id;
            const tripProtectionProvider = tripProtectionAddon?.attributes?.meta?.trip_protection_provider;

            adjustmentsData = {
              ...cancelSchedule?.attributes,
              check_in_date: reservation?.attributes.first_night,
              trip_protection: tripProtectionProvider
                ? {
                    type: tripProtectionAddon?.attributes?.name,
                    desc: getTripProtectionName(tripProtectionFeeId),
                  }
                : null,
            };
          }

          const remainingDays = calculateDaysUntilCheckin(adjustmentsData?.check_in_date);
          setAdjustmentsEvents(parseAdjustmentsToEvents(adjustmentsData));
          setAdjustments(adjustmentsData);
          setDaysUntilCheckin(remainingDays);
          setRequestFlag(true);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [adjustments, reservationUuid]);

  return (
    // @ts-ignore
    <CancellationScheduleContext.Provider
      value={{
        resInfo,
        adjustments,
        daysUntilCheckin,
        requestFlag,
        adjustmentsEvents,
      }}
    >
      {children}
    </CancellationScheduleContext.Provider>
  );
};

export default CancellationScheduleProvider;
