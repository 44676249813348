import React, {useContext} from "react";
import {VerticalTimeline} from "../vertical-timeline/VerticalTimeline";
import {Image} from "@vacasa/react-components-lib";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import cx from "classnames";
import styles from "./CancellationSchedule.module.scss";
import {CancellationScheduleContext} from "../../contexts/CancellationScheduleContext";
import {TripProtection} from "../trip-protection/TripProtection";
import {Checkin} from "../checkin/Checkin";

interface CancellationScheduleProps {
  title: string;
}

export const CancellationSchedule: React.FunctionComponent<CancellationScheduleProps> = ({title}) => {
  const cancellationScheduleContext = useContext(CancellationScheduleContext);
  const {requestFlag, adjustments, daysUntilCheckin, adjustmentsEvents} = cancellationScheduleContext;

  return (
    <>
      {!requestFlag ? (
        // Spinner while waiting for the request to complete
        <div className={styles.spinnerContainer}>
          <Image.Spinner className={styles.spinner} width={70} />
        </div>
      ) : (
        <div className={styles.cancellationScheduleBox}>
          <div className={cx(styles.title, "fontHeadingCard")}>{title}</div>

          {/* If the schedule is empty or adjustments are null, display a message that says "Work in progress. More details coming soon." */}
          {isNil(adjustments) || isEmpty(adjustments?.schedule) ? (
            <div>
              Work in progress.
              <br />
              More details coming soon.
            </div>
          ) : (
            <>
              <VerticalTimeline events={adjustmentsEvents} />
              {!isNil(daysUntilCheckin) && daysUntilCheckin >= 0 ? <Checkin daysUntilCheckin={daysUntilCheckin} /> : null}
              {!isNil(adjustments.trip_protection) ? <TripProtection name={adjustments.trip_protection.desc} /> : null}
            </>
          )}
        </div>
      )}
    </>
  );
};
