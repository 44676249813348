import {FrontendConfiguration} from "@common/configuration";
import moment from "moment-timezone";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import {getCheckinDateFormatted} from "../checkin/Checkin";
import {AdjustmentsData, CancellationScheduleEventsParsed} from "../../typing/cancel-schedule";
import {ALLOWED_LABELS} from "../../constants/constants";

export const parseAdjustmentsToEvents = (adjustments: AdjustmentsData): Array<CancellationScheduleEventsParsed> => {
    // Get the index of the first active event
    const initialActive = () => {
        const activeIndex = adjustments?.schedule
            .filter((scheduleEvent) => {
                return !isNil(scheduleEvent.eligibility.start) && !isNil(scheduleEvent.eligibility.end);
            })
            .map((scheduleEvent) => {
                return !!scheduleEvent.active;
            })
            .indexOf(true);
        return activeIndex !== -1 ? activeIndex : adjustments?.schedule.length;
    };

    // Return the schedule events to be displayed
    const parsedEvents = adjustments?.schedule
        ?.filter((scheduleEvent) => {
            return !isNil(scheduleEvent.eligibility.start) && !isNil(scheduleEvent.eligibility.end);
        })
        ?.map((scheduleEvent, index) => {
            let lines = [];
            ALLOWED_LABELS.forEach((allowedLabel) => {
                lines.push({
                    label: allowedLabel.label,
                    desc:
                        !isNil(adjustments.trip_protection) && allowedLabel.type === "future_stay_credit"
                            ? `Not eligible with ${adjustments.trip_protection.desc}`
                            : isEmpty(scheduleEvent.repayment) || isNil(scheduleEvent.repayment[allowedLabel.type])
                            ? "Not eligible"
                            : !isEmpty(scheduleEvent.repayment) && !isNil(scheduleEvent.repayment[allowedLabel.type])
                            ? scheduleEvent.repayment[allowedLabel.type].desc
                            : "",
                    link:
                        isNil(adjustments.trip_protection) &&
                        allowedLabel.type === "future_stay_credit" &&
                        adjustments?.desc_codes.includes("FSC_SAME_UNIT_ONLY")
                            ? {
                                  url: FrontendConfiguration.getSameUnitOnlyLink(),
                                  desc: "Same unit only.",
                              }
                            : null,
                });
            });

            let date_desc = getCheckinDateFormatted(adjustments.check_in_date, scheduleEvent);
            let title = scheduleEvent.eligibility.desc;
            let active = scheduleEvent.active;

            if (index < initialActive())
                title += ` (ended ${moment.tz(scheduleEvent.eligibility.end, FrontendConfiguration.getTimeZone()).format("YYYY-MM-DD")})`;
            else if (scheduleEvent.repayment?.refund?.desc === "100%" && scheduleEvent.repayment?.future_stay_credit?.desc === "100%")
                title += ` | Full refund`;
            else if (isEmpty(scheduleEvent.repayment?.refund) && isEmpty(scheduleEvent.repayment?.future_stay_credit)) title += ` | No refund`;

            if (adjustments?.desc_codes.includes("SAME_DAY_BOOKING")) {
                const checkinMinusCurrentDate = moment(adjustments.check_in_date).diff(
                    moment().tz(FrontendConfiguration.getTimeZone()).format("YYYY-MM-DD"),
                    "days"
                );
                active = checkinMinusCurrentDate === 0;
                title = "Same day booking";
                lines = [{label: "No Refund", desc: "for same day bookings", link: null}];
            }

            return {
                active,
                title,
                lines,
                date_desc,
            };
        });

    return parsedEvents;
};
