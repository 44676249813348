export const DOMAIN = window.location.hostname;
export const LOCAL = "development";
export const STAGING = "staging";
export const PRODUCTION = "production";

const ENV_LOCAL = "localhost";
const ENV_STAGE = "vacasastage.";
const ENV_PROD = "vacasa.";

const OPTIMIZELY_DEV_KEY = "5vJQvYSGDv26Wvx7XY1t6i";
const OPTIMIZELY_STAGE_KEY = "J61kUHotA2vA5D97F9uct8";
const OPTIMIZELY_PROD_KEY = "4mV9mPoQt3a7Z5caXEc7HF";

let env: string = null;
let optimizelySdkKey: string = null;

if (DOMAIN.includes(ENV_LOCAL)) {
    env = LOCAL;
    optimizelySdkKey = OPTIMIZELY_DEV_KEY;
} else if (DOMAIN.includes(ENV_STAGE)) {
    env = STAGING;
    optimizelySdkKey = OPTIMIZELY_STAGE_KEY;
} else if (DOMAIN.includes(ENV_PROD)) {
    env = PRODUCTION;
    optimizelySdkKey = OPTIMIZELY_PROD_KEY;
} else {
    env = LOCAL;
    optimizelySdkKey = OPTIMIZELY_DEV_KEY;
}

export const ENVIRONMENT = env;
export const OPTIMIZELY_SDK_KEY = optimizelySdkKey;
