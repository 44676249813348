import React from "react";
import styles from "./Checkin.module.scss";
import cx from "classnames";
import {FiCalendar} from "react-icons/fi";

type CheckinProps = {
  daysUntilCheckin: number;
};

export const Checkin: React.FunctionComponent<CheckinProps> = ({daysUntilCheckin}) => {
  return (
    <div className={cx(styles.additional, "flex")}>
      <div className={cx("columnFlex", styles.additionalInfoIconBox)}>
        <FiCalendar className={styles.additionalInfoIcon} size={18} />
      </div>
      <div className={cx("columnFlex", "fontBodyRegularDefault", styles.additionalInfoDesc)}>
        {daysUntilCheckin === 1 ? `${daysUntilCheckin} day` : `${daysUntilCheckin} days`} until check-in
      </div>
    </div>
  );
};
