import React from "react";
import styles from "./TripProtection.module.scss";
import cx from "classnames";
import {FiCheck} from "react-icons/fi";

type TripProtectionProps = {
  name: string;
};

export const TripProtection: React.FunctionComponent<TripProtectionProps> = ({name}) => {
  return (
    <div className={cx(styles.additional, "flex")}>
      <div className={cx("columnFlex", styles.additionalInfoIconBox)}>
        <span className={styles.additionalInfoIcon}>
          <FiCheck size={18} style={{color: "green"}} />
        </span>
      </div>
      <div className={"columnFlex"}>{name} on reservation</div>
    </div>
  );
};
