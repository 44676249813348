import React, {useEffect} from "react";
import {CancellationSchedule} from "../cancellation-schedule/CancellationSchedule";
import {createInstance, OptimizelyProvider, setLogger} from "@optimizely/react-sdk";

import {OPTIMIZELY_SDK_KEY} from "../../config/optimizely";
import {nanoid} from "nanoid";
import CancellationScheduleProvider from "../../contexts/CancellationScheduleContext";

const optimizely = createInstance({sdkKey: OPTIMIZELY_SDK_KEY});

export const App: React.FunctionComponent<any> = () => {
  useEffect(() => {
    setLogger(null);
  }, []);

  return (
    <>
      {/* @ts-ignore */}
      <OptimizelyProvider optimizely={optimizely} user={{id: nanoid()}}>
        <CancellationScheduleProvider>
          <div style={{marginTop: "15px"}}>
            <CancellationSchedule title={"Cancellation Schedule"} />
          </div>
        </CancellationScheduleProvider>
      </OptimizelyProvider>
    </>
  );
};
