import styles from "./VerticalTimeline.module.scss";

import React, {useState} from "react";
import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import {makeStyles} from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import {CancellationScheduleEventsParsed} from "../../typing/cancel-schedule";

interface VerticalTimelineProps {
  events: Array<CancellationScheduleEventsParsed>;
}

const useStyles = makeStyles({
  missingOppositeContent: {
    "&:before": {
      display: "none",
    },
  },
});

export const VerticalTimeline: React.FunctionComponent<VerticalTimelineProps> = ({events}) => {
  const initialActive = () => {
    const activeIndex = events
      ?.map((event) => {
        return !!event.active;
      })
      ?.indexOf(true);
    return activeIndex !== -1 ? activeIndex : events.length;
  };

  const [active] = useState(initialActive());
  const [clicked, setClicked] = useState(initialActive());
  const classes = useStyles();

  return (
    <Timeline
      classes={{
        root: styles.scheduleBox,
      }}
    >
      {events?.map((event, index) => {
        return (
          <TimelineItem
            key={index}
            classes={{
              missingOppositeContent: classes.missingOppositeContent,
              root: styles.eventRow,
            }}
          >
            <TimelineSeparator
              classes={{
                root: styles.timelineSection,
              }}
            >
              <div className={index === active ? styles.verticalBarTopBoxActive : styles.verticalBarTopBox}>
                <TimelineConnector
                  classes={{
                    root:
                      index === 0
                        ? styles.hidden
                        : index <= active
                        ? cx(styles.verticalBar, styles.verticalBarTop, styles.pastVerticalBar)
                        : cx(styles.verticalBar, styles.verticalBarTop),
                  }}
                />
              </div>
              <TimelineDot
                classes={{
                  root: cx(styles.dot, event.active ? styles.activeDot : index < active ? styles.pastDot : styles.passiveDot),
                }}
              />
              <TimelineConnector
                classes={{
                  root:
                    index === events.length - 1
                      ? styles.hidden
                      : index < active
                      ? cx(styles.verticalBar, styles.pastVerticalBar)
                      : styles.verticalBar,
                }}
              />
            </TimelineSeparator>
            <TimelineContent
              classes={{
                root: styles.timelineContent,
              }}
            >
              <div
                className={
                  index < active
                    ? cx(styles.clickable, "fontBodyRegularDefault")
                    : index === clicked
                    ? "fontBodyBoldDefault"
                    : cx(styles.clickable, "fontFieldLabel")
                }
                onClick={() => setClicked(index)}
              >
                {event.title}
              </div>
              {!isEmpty(event.lines) ? (
                <div className={cx(index !== clicked ? (event.title !== "Same day booking" ? styles.hidden : null) : null, styles.eventRowLines)}>
                  {event.lines?.map((line, index) => {
                    return (
                      <div key={index}>
                        {line.label ? <span className={cx("fontBodyBoldSmall", styles.lineLabel)}>{line.label}</span> : null}
                        <span className={"fontBodyRegularSmall"}>{line.desc}</span>
                        {!isNil(line.link) ? (
                          <a className={cx("fontBodySemiBoldSmall", styles.link)} href={line.link.url} target={"_blank"} rel="noopener noreferrer">
                            {line.link.desc}
                          </a>
                        ) : null}
                      </div>
                    );
                  })}
                  {event.date_desc ? <div className={cx("fontBodyRegularTiny", styles.dateDesc)}>{event.date_desc}</div> : null}
                </div>
              ) : null}
              {index !== clicked ? <div className={styles.eventSeparator}></div> : null}
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};
