interface TripProtectionInfo {
    id: string;
    name: string;
    provider: string;
}

export enum TripProtectionProviders {
    RED_SKY = "5",
    PLAY_TRAVEL_PROTECTION_STD = "1350",
    PLAY_TRAVEL_PROTECTION_CFAR = "1351",
}

export const TRAVEL_INSURANCES: Record<TripProtectionProviders, TripProtectionInfo> = {
    [TripProtectionProviders.RED_SKY]: {
        id: TripProtectionProviders.RED_SKY,
        name: "Red Sky",
        provider: "red_sky",
    },
    [TripProtectionProviders.PLAY_TRAVEL_PROTECTION_STD]: {
        id: TripProtectionProviders.PLAY_TRAVEL_PROTECTION_STD,
        name: "Play Travel Protection STD",
        provider: "rental_guardian",
    },
    [TripProtectionProviders.PLAY_TRAVEL_PROTECTION_CFAR]: {
        id: TripProtectionProviders.PLAY_TRAVEL_PROTECTION_CFAR,
        name: "Play Travel Protection CFAR",
        provider: "rental_guardian",
    },
};

export const ALLOWED_LABELS = [
    {
        type: "refund",
        label: "Refund",
    },
    {
        type: "future_stay_credit",
        label: "FSC",
    },
];
